<template>
  <div id="app">
    <div>
      <div id="ccpContainer" ref="ccpContainer"></div>
      <div id="right-panel">
        <div id="attributesDiv">
          <div id="loading" v-if="loading"></div>
          <div v-if="token">
            <div class="heading" style="border-bottom: 1px solid #bbb;"><label>Contact Method</label>
              <select class="text-input" name="contact" v-model="contact" style="width: 320px;">
                <option value="">-- select --</option>
                <option v-for="c in contacts" :value="c" v-bind:key="c.ref">{{c.title}}</option>
              </select>
            </div>
            <div class="heading" style="border-bottom: 1px solid #bbb;"><label>Account Type</label>
              <select class="text-input" name="product" v-model="product" style="width: 320px;">
                <option value="">-- select --</option>
                <option v-for="p in products" :value="p.fundProductId" v-bind:key="p.fundProductId">
                  {{p.productReference}}</option>
              </select>
            </div>
            <div class="heading" style="border-bottom: 1px solid #bbb;"><label>Member Number</label><input
                class="text-input" style="width: 200px;" name="memberNumber" v-model="memberNumber"></div>
            <div id="wrap-ups">
              <div class="heading" style="border-bottom: 1px solid #bbb;"><label>Wrap-up</label>
                <select class="text-input" name="category" @change="onChange($event)" v-model="wrapCat">
                  <option value="">-- select --</option>
                  <option v-for="s in selectCats" :value="s" v-bind:key="s">{{s}}</option>
                </select>
                <select class="text-input" name="subcategory" v-model="wrapSubCat" :disabled="wrapCat == ''">
                  <option value="">-- select --</option>
                  <option v-for="s in selectSubCats" :value="s" v-bind:key="s">{{s}}</option>
                </select>
                <textarea style="margin-left: 150px; width: 320px; height: 80px;" v-model="notes"
                  placeholder="Optional case notes"></textarea>
              </div>

            </div>
            <div class="heading logCall">
              <label style="margin-left: 150px"><input type="checkbox" v-model="closeCase" />Close case</label>
              <button @click="submitLog" :disabled="wrapSubCat == ''">Log call</button>
              <div v-if="errorMember" style="margin-left: 150px; color: red;">Error logging call. Please check member
                number and account type</div>
            </div>
            <div class="logout">
              <span @click="logout">Log out of DLTA</span>
            </div>
          </div>
          <div v-else>
            <div class="heading">Enter your Tina login credentials</div>
            <div class="heading"><label>Email</label><input class="text-input" name="email" v-model="email"
                autofill="false"></div>
            <div class="heading"><label>Password</label><input type="password" class="text-input" name="password"
                v-model="password"></div>
            <div class="errorText"><span v-if="errorText">Incorrect login credentials</span></div>
            <div><button @click="login" style='width: 100px; margin-left: 300px;'>Login</button></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import "amazon-connect-streams";
const axios = require('axios');
const config = require('./config.json');

const mw = axios.create({
  baseURL: config.apiUrl
});

export default {
  name: 'App',
  data() {
    return {
      member: {},
      memberNumber: null,
      incomingNumber: null,
      messageSent: false,
      selectCats: [''],
      selectSubCats: [''],
      products: [],
      product: '',
      contact: '',
      wrapCat: '',
      wrapSubCat: '',
      notes: '',
      isInbound: true,
      contactId: null,
      token: null,
      userId: null,
      password: null,
      email: null,
      ccpUrl: config.ccpUrl,
      loading: false,
      errorText: false,
      errorMember: false,
      closeCase: true,
      contacts: [
        {
          ref: 'callInbound',
          title: 'Call - Inbound'
        },
        {
          ref: 'callOutbound',
          title: 'Call - Outbound'
        },
        {
          ref: 'walkIn',
          title: 'Walk In'
        }
      ],
      wrapUps: [
        {
          category: 'Account Details',
          subcategories: [
            {
              name: 'Member Number'
            },
            {
              name: 'ABN/USI'
            },
            {
              name: 'Choice of Fund'
            },
            {
              name: 'Fund Details'
            },
            {
              name: 'Account Fees'
            },
            {
              name: 'MOL'
            },
            {
              name: 'Update Contact Details'
            },
            {
              name: 'New Spouse Account'
            },
            {
              name: 'Security Check Failed'
            },
            {
              name: 'Security Concerns'
            }
          ]
        },
        {
          category: 'Employer',
          subcategories: [
            {
              name: 'Contributions Enquiry'
            },
            {
              name: 'Member Status'
            }
          ]
        },
        {
          category: 'Investments',
          subcategories: [
            {
              name: 'Performance'
            },
            {
              name: 'Fees'
            },
            {
              name: 'Switch'
            },
            {
              name: 'Significant Event'
            },
            {
              name: 'Complaint'
            },
            {
              name: 'General Advice'
            }
          ]
        },
        {
          category: 'Insurance',
          subcategories: [
            {
              name: 'Cover (type/amt)'
            },
            {
              name: 'Change cover'
            },
            {
              name: 'Opt-in'
            },
            {
              name: 'Cancel'
            },
            {
              name: 'Quote'
            },
            {
              name: 'Claim'
            },
            {
              name: 'Complaint'
            },
            {
              name: 'General Advice'
            },
          ]
        },
        {
          category: 'Legal/tax',
          subcategories: [
            {
              name: 'BDBN'
            },
            {
              name: 'PTDN'
            },
            {
              name: 'Div293'
            },
            {
              name: 'EPOA'
            },
            {
              name: 'Authority'
            },
            {
              name: 'Family Law'
            },
            {
              name: 'Complaint'
            },
            {
              name: 'General Advice'
            },
          ]
        },
        {
          category: 'Money In',
          subcategories: [
            {
              name: 'Increase contributions'
            },
            {
              name: 'Pre-Tax'
            },
            {
              name: 'After-Tax'
            },
            {
              name: 'Cont. splitting'
            },
            {
              name: 'Cont. caps'
            },
            {
              name: 'Catch up SS'
            },
            {
              name: 'Bring forward'
            },
            {
              name: 'Work test'
            },
            {
              name: 'Roll in / consol'
            },
            {
              name: 'New account'
            },
            {
              name: 'Complaint'
            },
            {
              name: 'General Advice'
            },
          ]
        },
        {
          category: 'Money Out',
          subcategories: [
            {
              name: 'Std withdrawal'
            },
            {
              name: 'Early release'
            },
            {
              name: 'Roll out'
            },
            {
              name: 'Transfer to SMSF'
            },
            {
              name: 'DASP'
            },
            {
              name: 'Kiwisaver/OS transfer'
            },
            {
              name: 'Cont. splitting'
            },
            {
              name: 'FHSSS'
            },
            {
              name: 'Complaint'
            },
            {
              name: 'General Advice'
            },
          ]
        },
        {
          category: '3rd Party Authority',
          subcategories: [
            {
              name: 'Rundown'
            },
            {
              name: 'Authority'
            },
            {
              name: 'Client summary'
            },
            {
              name: 'Transactional query'
            },
            {
              name: 'Complaint'
            },
          ]
        },
        {
          category: 'Referral/Transfer',
          subcategories: [
            {
              name: 'Advice booking'
            },
            {
              name: 'Advice callback'
            },
            {
              name: 'Insurance transfer'
            },
            {
              name: 'Escalation'
            },
            {
              name: 'Complaint'
            },
          ]
        }
      ],
      handleContactConnected: (contact) => {
        this.isInbound = contact.isInbound();
        if (this.isInbound) {
          this.contact = this.contacts[0];
        } else {
          this.contact = this.contacts[1];
        }
        this.contactId = contact.getContactId();
      },
    }
  },
  mounted() {
    this.selectCats = this.wrapUps.map(w => w.category);
    var ccpUrl = this.ccpUrl + "/connect/ccp#/";
    this.checkToken();
    // this.checkUser()
    // Initialize the CCP window
    // eslint-disable-next-line no-undef
    connect.core.initCCP(this.$refs.ccpContainer, {
        ccpUrl: ccpUrl,        
        loginPopup: true,
        loginPopupAutoClose: true,        
        softphone: {
            allowFramedSoftphone: true
        }
    });
    connect.contact((contact) => {
        console.log(contact);
        if (contact.getActiveInitialConnection()
            && contact.getActiveInitialConnection().getEndpoint()) { 
            this.incomingNumber = contact.getActiveInitialConnection().getEndpoint().phoneNumber;
        } else {
            console.log('Contact already exists');
        }
        contact.onConnected(this.handleContactConnected);
    });
    setInterval(this.checkPerms, 180000);
    this.getProducts();
  },
  methods: {
    onChange(event) {
      const cat = event.target.value;
      if (cat && cat != '') {
        const selcat = this.wrapUps.find(e => cat === e.category);
        this.selectSubCats = selcat.subcategories.map(s => s.name);
      }
    },
    clearMember() {
      this.member = {};
      this.memberNumber = null;
      this.wrapCat = '';
      this.wrapSubCat = '';
      this.contactId = null;
      this.contact = '';
      this.errorMember = false;
      this.product = '';
      this.closeCase = true;
      this.notes = '';
    },
    submitLog() {
      if (!this.contact.ref) {
        return alert('No contact method detected');
      }
      if ((this.contact.ref == 'callInbound' || this.contact.ref == 'callOutbound' ) && !this.contactId) {
        return alert('No phone contact detected.');
      }
      if (!this.product) {
        return alert('Please select the members account type.');
      }
      if (!this.memberNumber) {
        return alert('Please enter a member number.');
      }
      this.loading = true;
      var status = 1;
      var outcome = null;
      if (this.closeCase) {
        status = 3;
        outcome = 3;
      }
      const payload = {
        "fundProductId": this.product,
        "memberId": this.memberNumber,
        "data": {
          "type": this.contact.title,
          "notes": (this.contactId ? this.ccpUrl + '/connect/contact-trace-records/details/' + this.contactId + '?tz=Australia/Brisbane' : '') + (this.notes != '' ? " - " + this.notes : ''),
          "configReference": this.contact.ref,
          "additionalReference": this.wrapCat + ' - ' + this.wrapSubCat,
          "creatorReference": this.userId,
          "handlerReference": this.userId,
          "status": status,
          "outcome": outcome
        }
      }
      mw.post('logcase', payload, { headers: { 'Tina-Api-Key': this.token }})
      .then((res) => {
        this.clearMember();
        this.loading = false;
      })
      .catch((err) => {
        this.errorMember = true;
        this.loading = false;
     })
    },
    checkToken() {
      const savedToken =  localStorage.getItem('authToken');
      let token = null;
      if (savedToken) token = JSON.parse(savedToken);
      const timestamp = Date.now();
      if (token && token.ttl && token.ttl > timestamp) {
        this.token = token.token;
        const savedUser = localStorage.getItem('userId');
        let userId = null;
        if (savedUser) {
          userId = JSON.parse(savedUser);
          this.userId = userId.id;
        }
      } else {
        this.token = null;
        localStorage.removeItem('authToken');
      }
    },
    checkUser() {
      const savedUser =  localStorage.getItem('userId');
      let userId = null;
      if (savedUser) userId = JSON.parse(savedUser);
      const timestamp = Date.now();
      if (userId && userId.ttl && userId.ttl > timestamp) {
        this.userId = userId.id;
      } else {
        this.userId = null;
        localStorage.removeItem('userId');
      }
    },
    login() {
      this.loading = true;
      mw.post('auth', {
        email: this.email,
        password: this.password
      }).then((res) => {
        console.log(res.data);
        this.errorText = false
        localStorage.setItem('authToken', JSON.stringify({ token: res.data.jwt, ttl: Date.now() + (60 * 60 * 12 * 1000)}));
        this.token = res.data.jwt;
        localStorage.setItem('userId', JSON.stringify({ id: res.data.user.id, ttl: Date.now() + (60 * 60 * 12 * 1000) }));
        this.userId = res.data.user.id;
        this.getProducts();
        this.loading = false;
      }).catch((err) => {
        this.errorText = true
        this.loading = false;
      });
    },
    logout() {
      this.token = null;
      localStorage.removeItem('authToken');
    },
    checkPerms() {
      if (!this.token) return;
      mw.get('me', { headers: { 'Tina-Api-Key': this.token }})
      .then((res) => {
        console.log(res.data);
        this.checkToken();
      })
      .catch((err) => {
        console.log(err);
      });
    },
    getProducts() {
      this.loading = true;
      mw.get('products', { headers: { 'Tina-Api-Key': this.token }})
      .then((res) => {
        console.log(res.data);
        this.products = res.data;
        this.loading = false;
      })
      .catch((err) => {
        console.log(err);
        this.loading = false;
      });
    }
  }
}
</script>

<style>
body {
  margin: 0;
  padding: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
#ccpContainer {
  width: 320px; 
  min-width: 200px; 
  height: 515px; 
  min-height: 400px; 
  float:left; 
  overflow: hidden;
}
#right-panel {
  width: 520px;
  float:left;
  background-color: #FFFFFF;
  height: 515px; 
  min-height: 400px; 
  border-left: 1px solid #ddd;
}
#attributesDiv {
  color: #000;
  width: 100%;
  padding-top: 10px;
  position: relative;
}
#loading {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255,255,255, 0.7);
}
.heading {
  margin-top: 10px;
  font-size: 15px;
  font-weight: bold;
  padding: 0px 10px 10px 20px;
}
.errorText {
  height: 30px;
  color: red;
  padding-left: 200px;
}
label {
  display: block;
  float: left;
  padding: 5px 0px 15px;
  width: 150px;
}
.text-input, select {
  font-size: 16px;
  padding: 4px;
  width: 200px;
  margin-right: 10px;
}
select {
  margin-bottom: 10px;
}
button {
  font-size: 16px;
  padding: 4px;
}
.member-details {
  padding: 10px;
  background-color: #FFF;
  margin: 10px 20px;
  position: relative;
}
.subheading {
  font-weight: bold;
  color: #000;
  font-size: 15px;
  margin: 5px 0px 5px;
}
.details {
  font-size: 18px;
}
a {
  font-size: 15px;
}
.clear {
  position: absolute; 
  right: 10px; 
  top: 10px;
  cursor: pointer;
  padding: 3px 5px;;
  border: 1px solid #999;
  font-size: 13px;
}
.logout {
  padding: 20px;
  margin-top: 0px;
}
.logout span {
    color: #1166BB;
    font-weight: bold;
    cursor: pointer;
  }
</style>
